import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Breadcrumb from '../../components/breadcrumb'
import SectorPage from '../../components/sectorPage'

const LuxuryPage = ({location}) => {
  return (
    <Layout year="2021" simpleHeader={true} url="/2021/luxe" type="sector">
      <SEO 
        title="Classement des meilleurs sites du secteur du luxe - 2021"
        description="Quels sont les meilleurs sites du secteur du luxe de l'Observatoire de l'impact positif ? Analyse des sites de luxe français Dior, Chanel, Vuitton, Cartier, Hermes."
        path="/2021/luxe"
        year="2021" />

      <Breadcrumb url="/2021/luxe" label="Luxe" year="2021" /> 

      <SectorPage slug="luxury" type="sector" year="2021" />
    </Layout>
  )
  }

export default LuxuryPage

